import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "reactstrap"
import { FaCircle } from "react-icons/fa"
import BackgroundImage from "gatsby-background-image"
import Newsletter from "../components/newsletter"

const Dots = () =>
  <>
    <FaCircle className="text-secondary" size="0.4em" /><br />
    <FaCircle className="text-primary"  size="0.4em" /><br />
    <FaCircle className="text-black" size="0.4em" />
  </>;

export default ({data}) => {
  const backgroundFluidImageStack = [
    data.banner.childImageSharp.fluid,
    `linear-gradient(to right, rgba(2, 47, 96, 0.9), rgba(2, 47, 96, 0))`
  ].reverse();

  return (<Layout>
    <SEO 
      title="Solutions" 
      description="We help you regaining your supply chain sovereignty."
    />

    <BackgroundImage
        Tag="section"
        className="d-flex justify-content-center align-items-center vh-60"
        fluid={backgroundFluidImageStack}
        backgroundColor={`#022f60`}
      >
      <Container>
        <Row>
          <Col lg="4">
            <h1 className="display-3 text-white">Solutions.</h1>
            <p className="lead text-white">
              We help you regaining your supply chain sovereignty.
            </p>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <section className="py-5">
      <Container>
      <Row className="align-items-center justify-content-center">
          <Col className="text-center" xl="5">
            <h6 className="text-uppercase text-secondary">Base Visibility</h6>
            <h2 className="display-5">Your Network. Your Structure.</h2>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center my-2">
          <Col className="text-center">
            <Dots />
          </Col>
        </Row>
        <Row className="mt-5 align-items-center">
          <Col xl={{ size: 5, offset: 1, order: 2}}>  
            <h6 className="text-uppercase text-secondary">Structural Network Analysis</h6>
            <h3 className="display-5">
              Do you know your network well enough? 
            </h3>
            <p className="lead">
              Tell us what you know about your company network with the names of your customers and suppliers.
              We then jointly explore your network and highlight your dependency on your suppliers, customers, industries and markets.
            </p>
            <Button color="primary" outline tag={Link} to="/contact/" className="mb-3">Book a demo</Button>
          </Col>
          <Col xl="5">
            <div class="embed-responsive embed-responsive-16by9 shadow bg-light rounded">
              <p className="text-muted text-center small">Video in Progress</p>
            </div>
            {/*<div class="embed-responsive embed-responsive-16by9 shadow rounded">
              <iframe 
                className="rounded" 
                title="JRC LIVE Structural Network Analysis" 
                src="https://www.youtube.com/embed/uCNZWKfifvw?autoplay=1&version=3&loop=1&playlist=uCNZWKfifvw" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>*/}
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center my-2 mt-5 mb-3">
          <Col className="text-center">
            <Dots />
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center">
          <Col className="text-center" xl="5">
            <h6 className="text-uppercase text-secondary">Extend Visibility</h6>
            <h2 className="display-5">Monitoring and managing a living organism.</h2>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center mt-2 mb-5">
          <Col className="text-center">
            <Dots />
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col xl={{ size: 5, offset: 1, order: 2 }}>  
            <h6 className="text-uppercase text-secondary">Demand Uncertainty – Part Criticality</h6>
            <h3 className="display-5">Do you know your demand well enough?</h3>
            <p className="lead">
              Significantly changing your production quantity today – how will this impact your future part availability and supply demand?
            </p>
            <Button color="primary" outline tag={Link} to="/contact/" className="mb-3">Book a demo</Button>
          </Col>
          <Col xl="5">
            <div class="embed-responsive embed-responsive-16by9 shadow bg-light rounded">
              <p className="text-muted text-center small">Video in Progress</p>
            </div>
          </Col>
        </Row>

        <Row className="my-5 align-items-center">
          <Col xl="5">
            <h6 className="text-uppercase text-secondary">Time Uncertainty – Time Criticality</h6>
            <h3 className="display-5">Do you know your supply chain reliability well enough?</h3>
            <p className="lead">
              Will your shipments be on-time?
              What transport routes/partners/logistics hubs bring the highest risk/uncertainty to your network?
            </p>
            <Button color="primary" outline tag={Link} to="/contact/" className="mb-3">Book a demo</Button>
          </Col>
          <Col xl={{ size: 5, offset: 1}}>
            <div class="embed-responsive embed-responsive-16by9 shadow rounded">
              <iframe 
                className="rounded" 
                title="JRC LIVE Time Uncertainty and Criticality" 
                src="https://www.youtube.com/embed/J7UlAj8NsQY?autoplay=1&version=3&loop=1&playlist=J7UlAj8NsQY" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen>
              </iframe>
            </div>
          </Col>
        </Row>
        
        <Row className="align-items-center">
          <Col xl={{ size: 5, offset: 1, order: 2 }}>  
            <h6 className="text-uppercase text-secondary">Inventory Analysis - Part Criticality</h6>
            <h3 className="display-5">Do you know your local inventory levels and inventory ranges in your global network well enough?</h3>
            <Button color="primary" outline tag={Link} to="/contact/" className="mb-3">Book a demo</Button>
          </Col>
          <Col xl="5">
            <div class="embed-responsive embed-responsive-16by9 shadow rounded">
              <iframe 
                className="rounded" 
                title="JRC LIVE Inventory Analysis - Part Criticality" 
                src="https://www.youtube.com/embed/JYREnHHXAww?autoplay=1&version=3&loop=1&playlist=JYREnHHXAww" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen>
              </iframe>
            </div>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center my-2 mt-5 mb-3">
          <Col className="text-center">
            <Dots />
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center">
          <Col className="text-center" xl="5">
            <h6 className="text-uppercase text-secondary">Enhance Visibility</h6>
            <h2 className="display-5">Enhance visibility by integrating external data sources.</h2>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center my-2">
          <Col className="text-center">
            <Dots />
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col xl={{ size: 5, offset: 1, order: 2}}>  
            <h6 className="text-uppercase text-secondary">Fincancial distress</h6>
            <h3 className="display-5">Do you know the financial situation of your supply chain partners well enough?</h3>
            <p className="lead">
              Any financially distressed suppliers in your supply chain?
            </p>
            <Button color="primary" outline tag={Link} to="/contact/" className="mb-3">Book a demo</Button>
          </Col>
          <Col xl="5">
            <div class="embed-responsive embed-responsive-16by9 shadow rounded">
              <iframe title="JRC LIVE Financial Situation of your Suppliers" 
                className="rounded" 
                src="https://www.youtube.com/embed/tZagB2Tw99g?autoplay=1&version=3&loop=1&playlist=tZagB2Tw99g" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col xl="5">
            <h6 className="text-uppercase text-secondary">Track your shipments</h6>
            <h3 className="display-5">Do you know your transport arrivals well enough?</h3>
            <p className="lead">
              Do you know the arrival times of your transports in near-real time? 
              Will changing arrival times be updated and interpreted automatically in your system.
            </p>
            <Button color="primary" outline tag={Link} to="/contact/" className="mb-3">Book a demo</Button>
          </Col>
          <Col xl={{ size: 5, offset: 1}}>
            <div class="embed-responsive embed-responsive-16by9 shadow bg-light">
              <p className="text-muted text-center small">Video in Progress</p>
            </div>
          </Col>
        </Row>

        <Row className="mt-5 align-items-center justify-content-center text-center">
          <Col xl={{ size: 5 }}>  
            <h6 className="text-uppercase text-secondary">More to come</h6>
            <h3 className="display-5">Integrate and utilize any system you like.</h3>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center my-2 mt-3 mb-3">
          <Col className="text-center">
            <Dots />
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center">
          <Col className="text-center" xl="5">
            <h6 className="text-uppercase text-secondary">Get started</h6>
            <h2 className="display-6">Let JRC LIVE help you to increase your visibility.</h2>
            <p className="lead">
              Schedule a LIVE demo. Regain your supply chain sovereignty.
            </p>
            <Button color="primary" size="lg" tag={Link} to="/contact/">Book a demo</Button>
          </Col>
        </Row>
      </Container>
    </section>

    <Newsletter />
  </Layout>
)}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "solutions.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;